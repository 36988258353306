import getVideoId from 'get-video-id';

interface VideoOptions {
	videoUrl?: string;
	classSelector?: string;
}

export default (opt: VideoOptions = {}) =>
({
	service: null as string | null,
	videoId: null as string | null,
	embedUrl: null as string | null,
	// `wantsToPlay` signifies the user intent, independent of the loading state.
	// `true` when the play button is pressed, whether the video is still loading or not.
	wantsToPlay: false,
	// `isPlaying` is the playing state of the video, `false` when the video is buffering due to network delays.
	isPlaying: false,
	abortCtrl: null as AbortController | null,

	videoEl: null,

	init() {

		if (opt.videoUrl) {
			const videoObj = getVideoId(opt.videoUrl);
			this.service = videoObj.service;
			this.videoId = videoObj.id;

			if (this.service == 'youtube')
				this.embedUrl = `https://www.youtube.com/embed/${this.videoId}?modestbranding=1&showinfo=0&showsearch=0&loop=1&rel=0`;

			if (this.service == 'vimeo')
				this.embedUrl = `https://player.vimeo.com/video/${this.videoId}`;

		}

		this.videoEl = opt.classSelector ? this.$root.querySelector(`video.${opt.classSelector.split(' ').join('.')}`) : this.$root.querySelector('video');

		if (!this.videoEl)	return;
		this.videoEl.addEventListener("pause", e => this.isPlaying = false);
		// videoEl.addEventListener("waiting", e => this.isPlaying = false);
		this.videoEl.addEventListener("playing", e => this.isPlaying = true);

		this.wantsToPlay = this.videoEl?.getAttribute('autoplay') == 'autoplay' || this.videoEl?.getAttribute('autoplay') == 'true' || this.videoEl?.getAttribute('autoplay') == '1';
	},

	toggle() {
		if (this.wantsToPlay)
			this.pause();
		else
			this.play();
	},
	
	play() {
		if (!this.videoEl)	return;
		this.wantsToPlay = true;
		if (this.videoEl.paused) {
			let couldNotStart = this.videoEl.readyState < 2;
			try {
				// play() MUST be called inside the user event handler for video to play on mobile
				this.videoEl.play();
				couldNotStart = false;
			}
			catch (error) {
				couldNotStart = true;
				console.error('Error attempting to play video:', error);
			}
			// if (videoEl.readyState >= 2) {
			// 	videoEl.play().catch(error => {
			// 		console.error('Error attempting to play video:', error);
			// 	});
			// }
			if (couldNotStart) {
				if (this.abortCtrl && !this.abortCtrl.aborted) 
					this.abortCtrl.abort();
				this.abortCtrl = new AbortController();
				const signal = this.abortCtrl.signal;

				this.videoEl.addEventListener("canplay", e => {
					this.videoEl.play().catch(error => {
						console.error('Error attempting to play video:', error);
					});
				}, { signal, once: true });
			}
		}
	},

	pause() {
		if (!this.videoEl)	return;
		this.wantsToPlay = false;
		if (this.abortCtrl && !this.abortCtrl.aborted) 
			this.abortCtrl.abort();
		this.abortCtrl = null;
		this.videoEl.pause()
	}
})