/**
 * Calculates the number of working hours elapsed since a given date.
 * Working hours are defined as Monday-Friday, 9 AM to 5 PM.
 *
 * @param {string} dateString - ISO date string
 * @param {number} targetHours - Number of working hours to check against
 * @returns {boolean} - True if targetHours have elapsed during working hours
 */
export function haveWorkingHoursElapsed(dateString, targetHours) {
	const startDate = new Date(dateString);
	const now = new Date(Date.now());

	// If the dates are the same, just compare hours
	if (isSameDay(startDate, now)) {
		return getWorkingHoursInDay(startDate, now) >= targetHours;
	}

	let totalHours = 0;
	let currentDate = new Date(startDate);

	// Handle first day
	if (isWorkingDay(startDate)) {
		totalHours += getWorkingHoursInDay(startDate, getEndOfDay(startDate));
	}

	// Handle full days in between
	currentDate = getStartOfNextDay(startDate);
	while (currentDate < getStartOfDay(now)) {
		if (isWorkingDay(currentDate)) {
			totalHours += 8; // Full working day (9-5 = 8 hours)
		}
		currentDate.setDate(currentDate.getDate() + 1);
	}

	// Handle last day
	if (isWorkingDay(now)) {
		totalHours += getWorkingHoursInDay(getStartOfDay(now), now);
	}

	return totalHours >= targetHours;
}

/**
 * Helper function to check if two dates are the same calendar day
 */
function isSameDay(date1, date2) {
	return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

/**
 * Helper function to check if a date is a working day (Monday-Friday)
 */
function isWorkingDay(date) {
	const day = date.getDay();
	return day >= 1 && day <= 5; // 0 is Sunday, 6 is Saturday
}

/**
 * Helper function to get the start of day (midnight)
 */
function getStartOfDay(date) {
	const start = new Date(date);
	start.setHours(0, 0, 0, 0);
	return start;
}

/**
 * Helper function to get the end of day (23:59:59.999)
 */
function getEndOfDay(date) {
	const end = new Date(date);
	end.setHours(23, 59, 59, 999);
	return end;
}

/**
 * Helper function to get the start of next day
 */
function getStartOfNextDay(date) {
	const nextDay = new Date(date);
	nextDay.setDate(nextDay.getDate() + 1);
	nextDay.setHours(0, 0, 0, 0);
	return nextDay;
}

/**
 * Calculate working hours between two timestamps on the same day
 */
function getWorkingHoursInDay(start, end) {
	// Convert to working day boundaries if needed
	const workStart = new Date(start);
	workStart.setHours(9, 0, 0, 0);

	const workEnd = new Date(end);
	workEnd.setHours(17, 0, 0, 0);

	// Adjust start time if before 9 AM
	const effectiveStart = start < workStart ? workStart : start;
	// Adjust end time if after 5 PM
	const effectiveEnd = end > workEnd ? workEnd : end;

	// If start is after end or outside working hours, return 0
	if (effectiveStart >= effectiveEnd || effectiveStart.getHours() >= 17 || effectiveEnd.getHours() < 9) {
		return 0;
	}

	// Calculate hours difference
	return (effectiveEnd - effectiveStart) / (1000 * 60 * 60);
}
